import React from "react";
import { Box, Typography, Grid, Card, CardContent, Button, Link} from "@mui/material";
/*import { useNavigate } from "react-router-dom";*/
import img1 from "../Misj2025/banner.jpg";
import reglamento from "../Misj2025/REGLAMENTO MISJ 2025.pdf"
import concentimiento from "../Misj2025/CONSENTIMIENTO.pdf"

const Misj2025 = () => {
  /*const navigate = useNavigate();

  const handleInscribirse = (raceName, distance, periodoInscripcion, price, image) => {
    const numericPrice = parseFloat(price.replace(/\./g, "").replace(",", ".")); // Convierte '20.000,00' a 20000.00
    const priceTax = numericPrice * 0.0639; // Calcula el 6.39% del precio original
    // Redirige al componente Forms con los datos seleccionados
    navigate(`/${raceName}/forms02`, {
      state: { distance, periodoInscripcion, price: numericPrice, priceTax: priceTax.toFixed(2), image },
    });
  };*/

  return (
    <Box
      sx={{
        backgroundColor: "#f5f5f5",
        padding: 4,
        borderRadius: 2,
        maxWidth: "90%",
        margin: "auto",
        marginTop: 4,
        marginBottom: 4,
        fontFamily: "Arial, sans-serif",
        boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
      }}
    >
      {/* Banner Image */}
      <Box
        component="img"
        src={img1}
        alt="Banner"
        sx={{
          width: "100%",
          borderRadius: 2,
          marginBottom: 3,
          maxHeight: "300px",
        }}
      />

      <Typography
        variant="h6"
        gutterBottom
        sx={{
          fontFamily: "Monserat, sans-serif",
          fontWeight: 600,
          fontSize: "1.5rem",
          textTransform: "uppercase",
          textDecoration: "underline",
          textDecorationColor: "red",
          textDecorationThickness: "2px",
        }}
      >
        Información de la Carrera
      </Typography>
      <Typography variant="body1" sx={{textAlign: "center"}}>
        Te damos la bienvenida a la 9va edición de la Maratón de San Juan, un evento que ofrece un circuito renovado y único con condiciones climáticas ideales en el mes de julio (entre 6° y 17°C) para que puedas superar tus marcas.
        <br></br>
        La carrera de 42 km comienza entre montañas, desde el Dique Punta Negra, y continúa a lo largo del perilago, pasando por cerros y viñedos, cruzando la Quebrada de Zonda, el autódromo Eduardo Copello y transitando por la Ruta del Sol antes de llegar a la Avenida Libertador General San Martin. Este tramo está rodeado de un hermoso boulevard y una arboleda típica que te acompañará hasta el corazón de la ciudad de San Juan. En este tramo, recorrerás los lugares más emblemáticos de nuestra provincia, como la Casa de Gobierno, el Estadio Aldo Cantoni, el Parque de Mayo, la Casa Natal de Sarmiento y la Catedral de San Juan. Todo esto forma parte del atractivo del circuito, culminando y coronándote con una medalla en el pintoresco frente del Teatro del Bicentenario.
        <br></br>
        Además de la carrera de 42 km, también ofrecemos distancias de 21 km, 10 km y 5 km, por lo que todos pueden ser parte de la Fiesta Urbana del Running. Tanto los profesionales que buscan mejorar sus tiempos como los aficionados que desean disfrutar del deporte en el contexto de un gran evento que reúne a corredores de todo el país y el exterior.
        <br></br>
        Las distancias de 42km y 21km se encuentran certificadas por la WORLDATHLETICS y la C.A.D.A lo que significa una garantía de calidad, medición precisa del recorrido, reconocimiento internacional, seguridad para los corredores y una oportunidad para el ranking y la calificación.
        <br></br>
        #desdelamontañaalaciudad
        <br></br>
        Nota: Personas con alguna discapacidad certificada pueden acceder a un descuento en su inscripción enviando un mail a misj@adventurepro.com.ar adjuntando el C.U.D.
      </Typography>
   
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          marginTop: 8,
          fontFamily: "Monserat, sans-serif",
          fontWeight: 600,
          fontSize: "1.5rem",
          textTransform: "uppercase",
          textDecoration: "underline",
          textDecorationColor: "red",
          textDecorationThickness: "2px",
        }}
      >
        DOCUMENTACIÓN IMPORTANTE
      </Typography>
      
      {/* Enlace de vista previa del PDF */}
      <Button
        variant="contained"
        color="primary"
        href={reglamento} // Usa `href` en lugar de `src` para abrir el PDF
        target="_blank" // Abre el PDF en una nueva pestaña
        rel="noopener noreferrer" // Mejora de seguridad al abrir un enlace externo
        sx={{
          marginTop: 2,
          marginRight: 5,
          backgroundColor: "red",
          ":hover": { backgroundColor: "darkred", color: "white" },
        }}
      >
        Reglamento
      </Button>

      <Button
        variant="contained"
        color="primary"
        href={concentimiento} // Usa `href` en lugar de `src` para abrir el PDF
        target="_blank" // Abre el PDF en una nueva pestaña
        rel="noopener noreferrer" // Mejora de seguridad al abrir un enlace externo
        sx={{
          marginTop: 2,
          backgroundColor: "red",
          ":hover": { backgroundColor: "darkred", color: "white" },
        }}
      >
        CONSENTIMIENTO
      </Button>

      <Typography
        variant="h6"
        gutterBottom
        sx={{
          marginTop: 8,
          fontFamily: "Monserat, sans-serif",
          fontWeight: 600,
          fontSize: "1.5rem",
          textTransform: "uppercase",
          textDecoration: "underline",
          textDecorationColor: "red",
          textDecorationThickness: "2px",
        }}
      >
        Itinerario
      </Typography>
      <Typography variant="body1" sx={{textAlign: "left"}}>
      <Typography sx={{textDecoration: 'underline', fontWeight: 'bold'}}>Acreditaciones y Exporunning:</Typography>
        Horario: 10 a 19hs<br></br>Lugar: A Confirmar (Zona Céntrica)
      <Typography sx={{textDecoration: 'underline', fontWeight: 'bold', marginTop: 2}}>Carrera</Typography>
        Domingo 27 de Julio<br></br><strong>42km</strong><br></br>Hora: 8.30 am<br></br>Lugar: Embarcadero Dique Punta Negra.<br></br><strong>21km</strong><br></br>Hora: 9 am<br></br>Lugar: Quebrada de Zonda.<br></br>
        <strong>10km</strong><br></br>Hora: 9 am<br></br>Lugar: El Almendro Sport, Rivadavia.<br></br>
        <strong>5km</strong><br></br>Hora: 11 am<br></br>Lugar: Patio Alvear, Capital.<br></br>
        (Los horarios puede sufrir modificación)
        <Typography sx={{textDecoration: 'underline', fontWeight: 'bold', marginTop: 2}}>Llegadas</Typography>
        Todas las distancias llegan al Teatro del Bicentenario (Las Heras 430 Sur). Ciudad de San Juan.
        <Typography sx={{textDecoration: 'underline', fontWeight: 'bold', marginTop: 2}}>Traslados</Typography>
        Los traslados se pueden contratar enviando un mail a <Link href= 'trasladosyalojamiento@aventurepro.com.ar'>trasladosyalojamiento@aventurepro.com.ar</Link> Por favor colocar en el asunto TRASLADO MARATON DE SAN JUAN.<br></br>Punto de concentración: Teatro del Bicentenario. (a confirmar)<br></br>42km: 7 am<br></br>21km: 7.30 am<br></br>10km: 8 am
        <Typography sx={{textDecoration: 'underline', fontWeight: 'bold', marginTop: 2}}>PREMIACION EN EFECTIVO</Typography>
        General Masculino y Femenino de 42km.<br></br>- 1Ro: $ 100.000<br></br>- 2Do: $ 75.000<br></br>- 3Ro: $ 55.000<br></br><br></br>
        General Masculino y Femenino de 21km<br></br>- 1Ro: $ 50.000<br></br>- 2Do: $ 40.000<br></br>- 3Ro: $ 30.000<br></br><br></br>
        Record de Circuito 42km<br></br>- Femenino: $50.000<br></br>- Masculino: $50.000<br></br><br></br>
        Los récords en la distancia 42k son:<br></br>- Femenino 02:57:07 hs <br></br>- Masculino 02:30:46 hs
        <Typography sx={{textDecoration: 'underline', fontWeight: 'bold', marginTop: 2}}>Contactos Oficiales</Typography>
        Whatsapp: 264 44573797<br></br><Link href= 'misj@adventurepro.com.ar'>Mail: misj@adventurepro.com.ar</Link><br></br>Web: www.maratondesanjuan.com
      </Typography>
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          marginTop: 8,
          fontFamily: "Monserat, sans-serif",
          fontWeight: 600,
          fontSize: "1.5rem",
          textTransform: "uppercase",
          textDecoration: "underline",
          textDecorationColor: "red",
          textDecorationThickness: "2px",
        }}
      >
        Inscripciones
      </Typography>

      {/*Aqui arrancan las card de las distancias*/}
      <Grid container spacing={2}>

        <Grid item xs={12} sm={6} md={3}>
          <Card
            variant="outlined"
            sx={{
              maxWidth: 300,
              transition: "0.2s",
              boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
              borderRadius: 3,
              "&:hover": {
                transform: "scale(1.05)",
              },
            }}
          >
            <CardContent>
              <Typography variant="h2" gutterBottom>
                42K
              </Typography>
              <Typography variant="h7" gutterBottom>
                Periodo lanzamiento
              </Typography>
              <Typography variant="h5">$40.000</Typography>
            </CardContent>
            <Box sx={{ textAlign: "center", paddingBottom: 2 }}>
              {/*<Button
                variant="contained"
                color="error"
                onClick={() =>
                  handleInscribirse(
                    "Maraton Internacional De San juan 2025",
                    "42K",
                    "Periodo lanzamiento",
                    "40.000",
                    img1
                  )
                }
              >
                Inscribirse
              </Button>*/}
              <Box sx={{ textAlign: "center", backgroundColor: "rgba(255, 0, 0, 0.7)", color: "white", padding: 1 }}>
              <Typography variant="h6">Proximamente</Typography>
              {/*<Typography variant="h6" sx={{fontSize: '0.8rem'}}>Proximamente nuevo periodo</Typography>*/}
              </Box>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card
            variant="outlined"
            sx={{
              maxWidth: 300,
              transition: "0.2s",
              boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
              borderRadius: 3,
              "&:hover": {
                transform: "scale(1.05)",
              },
            }}
          >
            <CardContent>
              <Typography variant="h2" gutterBottom>
                21K
              </Typography>
              <Typography variant="h7" gutterBottom>
                Periodo lanzamiento
              </Typography>
              <Typography variant="h5">$35.000</Typography>
            </CardContent>
            <Box sx={{ textAlign: "center", paddingBottom: 2 }}>
              {/*<Button
                variant="contained"
                color="error"
                onClick={() =>
                  handleInscribirse(
                    "Maraton Internacional De San juan 2025",
                    "21K",
                    "Periodo lanzamiento",
                    "35.000",
                    img1
                  )
                }
              >
                Inscribirse
              </Button>*/}
              <Box sx={{ textAlign: "center", backgroundColor: "rgba(255, 0, 0, 0.7)", color: "white", padding: 1 }}>
              <Typography variant="h6">Proximamente</Typography>
              {/*<Typography variant="h6" sx={{fontSize: '0.8rem'}}>Proximamente nuevo periodo</Typography>*/}
              </Box>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card
            variant="outlined"
            sx={{
              maxWidth: 300,
              transition: "0.2s",
              boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
              borderRadius: 3,
              "&:hover": {
                transform: "scale(1.05)",
              },
            }}
          >
            <CardContent>
              <Typography variant="h2" gutterBottom>
                10K
              </Typography>
              <Typography variant="h7" gutterBottom>
                Periodo lanzamiento
              </Typography>
              <Typography variant="h5">$29.000</Typography>
            </CardContent>
            <Box sx={{ textAlign: "center", paddingBottom: 2 }}>
              {/*<Button
                variant="contained"
                color="error"
                onClick={() =>
                  handleInscribirse(
                    "Maraton Internacional De San juan 2025",
                    "10K",
                    "Periodo lanzamiento",
                    "29.000",
                    img1
                  )
                }
              >
                Inscribirse
              </Button>*/}
              <Box sx={{ textAlign: "center", backgroundColor: "rgba(255, 0, 0, 0.7)", color: "white", padding: 1 }}>
              <Typography variant="h6">Proximamente</Typography>
              {/*<Typography variant="h6" sx={{fontSize: '0.8rem'}}>Proximamente nuevo periodo</Typography>*/}
              </Box>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card
            variant="outlined"
            sx={{
              maxWidth: 300,
              transition: "0.2s",
              boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
              borderRadius: 3,
              "&:hover": {
                transform: "scale(1.05)",
              },
            }}
          >
            <CardContent>
              <Typography variant="h2" gutterBottom>
                5K
              </Typography>
              <Typography variant="h7" gutterBottom>
                Periodo lanzamiento
              </Typography>
              <Typography variant="h5">$25.000</Typography>
            </CardContent>
            <Box sx={{ textAlign: "center", paddingBottom: 2 }}>
              {/*<Button
                variant="contained"
                color="error"
                onClick={() =>
                  handleInscribirse(
                    "Maraton Internacional De San juan 2025",
                    "5K",
                    "Periodo lanzamiento",
                    "25.000",
                    img1
                  )
                }
              >
                Inscribirse
              </Button>*/}
              <Box sx={{ textAlign: "center", backgroundColor: "rgba(255, 0, 0, 0.7)", color: "white", padding: 1 }}>
              <Typography variant="h6">Proximamente</Typography>
              {/*<Typography variant="h6" sx={{fontSize: '0.8rem'}}>Proximamente nuevo periodo</Typography>*/}
              </Box>
            </Box>
          </Card>
        </Grid>

      </Grid>
    </Box>
  );
};

export default Misj2025;
